class single {
  constructor(container, cb) {
    this.container = container
    this.address_1 = container.getElementsByClassName('bas-address-1')?.[0]
    this.address_2 = container.getElementsByClassName('bas-address-2')?.[0]
    this.city = container.getElementsByClassName('bas-city')?.[0]
    this.state = container.getElementsByClassName('bas-state-id')?.[0]
    this.zip_code = container.getElementsByClassName('bas-zip-code')?.[0]

    this.autoComplete = null
    this.cb = cb
  }

  init() {
    if (this.container.classList.contains('applied')) {
      return
    }

    // Create the auto-complete object
    this.autoComplete = new google.maps.places.Autocomplete(this.address_1, {
      componentRestrictions: { country: ['us'] }, // Addresses in the US and Canada.
      fields: ['address_components', 'geometry'], // 'ALL'
      types: ['address'],
      // strictBounds: false,
    })

    // When the user selects an address from the drop-down, populate the address fields in the form.
    this.autoComplete.addListener('place_changed', () => this.fillAddress())

    this.address_1.addEventListener('keydown', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    })

    this.container.classList.add('applied')

    return this
  }

  fillAddress() {
    // Get the place details from the auto-complete object.
    const place = this.autoComplete.getPlace()
    if (!place.address_components) {
      return
    }

    this.address_2.value = ''
    this.city.value = ''
    this.state.value = ''
    this.zip_code.value = ''

    let addressV1 = ''
    let stateValue = ''

    // Get each component of the address from the place details, and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0]
      switch (componentType) {
        case 'street_number':
          addressV1 = `${component.long_name} ${addressV1}`
          break

        case 'route':
          addressV1 += component.long_name
          break

        case 'postal_code':
          this.zip_code.value = component.long_name
          break

        case 'postal_code_suffix':
          break

        case 'subpremise':
          this.address_2.value = component.long_name
          break

        case 'locality':
          this.city.value = component.long_name
          break

        case 'neighborhood':
        case 'sublocality_level_1':
          if (!this.city.value) {
            this.city.value = component.long_name
          }
          break

        case 'administrative_area_level_1':
          stateValue = component.short_name
          break

        case 'country':
          break
      }
    }

    for (let i = 0; i < this.state.options.length; i++) {
      let option = this.state.options[i] // Access the option element
      if (option.text === stateValue) {
        this.state.value = option.value
      }
    }

    this.address_1.value = addressV1

    // After filling the form with address components from the Autocomplete prediction,
    // set cursor focus on the second address line to encourage entry of subpremise information such as apartment, unit, or floor number.
    this.address_2.focus()

    jQuery(this.state).fSelect2()

    if (this.cb) {
      this.cb()
    }
  }
}

export default single
