import AddressHelper from './inc/address'
import mAjax from './mAjax'
import mCustomTable from './mCustomTable'
import mDataTable from './mDataTable'
import mDate from './inc/date'
import { mDom } from './mDom'
import mForm from './mForm'
import mHttp from './inc/http'
import mLoader from './inc/loader'
import mSafe from './inc/safe'
import mToast from './inc/toast'
import mTooltip from './inc/tooltip'
import mUrl from './mUrl'

export const mAddress = (div, cb) => new AddressHelper(div, cb).init()
export const mPageType = () => jQuery('body').attr('data-page-type')
export const mPageSubType = () => jQuery('body').attr('data-sub-page-type')

const mButton = {
  font: `font-medium-2`,
  class: (name, c) => (typeof name !== 'undefined' ? name : c),
  rowEdit: (url) =>
    `<a href="${url}" title="Edit" class="table-row-form"><i data-feather="edit" class="${mButton.font}"></i></a>`,
  rowEditAjax: (url) =>
    `<a href="${url}" title="Edit" class="table-row-form"><i data-feather="edit" class="${mButton.font}"></i></a>`,
  rowDelete: (url) =>
    `<a href="${url}" title="Delete" class="text-danger table-row-delete"><i data-feather="trash-2" class="${mButton.font}"></i></a>`,
  withdrawalAdd: (url) =>
    `<a href="${url}" title="Withdraw Amount" class="table-row-form"><i data-feather="dollar-sign" class="${mButton.font}"></i></a>`,

  view: (url) =>
    url
      ? `<a href="${url}" title="View" target="_blank"><i data-feather="eye" class="${mButton.font}"></i></a>`
      : ``,

  render: (actions) => {
    actions = typeof actions === 'object' ? actions : {}
    if (!Object.keys(actions).length) {
      return '-'
    }

    let list = []
    if (actions.editAjax) {
      list.push(mButton.rowEditAjax(actions.editAjax))
    }
    if (actions.edit) {
      list.push(mButton.rowEdit(actions.edit))
    }
    if (actions.delete) {
      list.push(mButton.rowDelete(actions.delete))
    }
    if (actions['withdrawal-add']) {
      list.push(mButton.withdrawalAdd(actions['withdrawal-add']))
    }
    if (actions['sub-investor-add']) {
      list.push(
        `<a href="${actions['sub-investor-add']}" title="Add Sub Investor" class="table-row-form"><i data-feather="plus" class="${mButton.font}"></i></a>`
      )
    }

    return list.length ? `<div class="col-actions">${list.join('')}</div>` : ''
  },

  make: (text, options = {}) => {
    let classes = [
      'btn waves-effect waves-float waves-light',
      options?.type ?? 'btn-primary',
      options?.class ?? '',
      options?.small ? 'btn-sm' : '',
    ]

    let attrs = [`class="${classes.join(' ')}"`, ...(options?.extraAttrs ?? [])]

    return `<button type="button" ${attrs.join(' ')}>${text}</button>`
  },
}

const mCaptchaCreator = (cb) => {
  if (typeof grecaptcha != 'undefined') {
    grecaptcha.ready(() =>
      grecaptcha
        .execute(jQuery('body').attr('data-gcptcha'), { action: 'submit' })
        .then((token) => cb(token))
    )
  } else {
    cb('')
  }
}

const mConfirm = (cb, args = {}) => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'Do you want to perform this action?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Perform',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-secondary ml-1',
    },
    ...args,
  }).then((result) => cb && cb(result.value))
}

const mFeatherApply = () => {
  if (typeof window.feather !== 'undefined') {
    window.feather.replace({
      width: 14,
      height: 14,
    })
  }
}

const mFieldFormatter = {
  // https://github.com/nosir/cleave.js/blob/master/doc/public-methods.md
  instances: {},

  apply: (elements, obj) => {
    elements.toArray().forEach(function (field) {
      mFieldFormatter.set(field, new Cleave(field, obj))
    })
  },

  set: (element, obj) => {
    mFieldFormatter.instances[element.id] = obj
  },

  get: (element) => {
    return mFieldFormatter.instances?.[element.attr('id')] ?? null
  },

  remove: (element) => {
    return mFieldFormatter.instances?.[element.attr('id')]?.destroy()
  },

  setValue: (element, value) => {
    return mFieldFormatter.get(element)
      ? mFieldFormatter.get(element)?.setRawValue(value)
      : element.val(value)
  },

  getValue: (element) => {
    return mFieldFormatter.get(element)
      ? mFieldFormatter.get(element)?.getRawValue()
      : element.val()
  },

  amount: (elements) => {
    if (elements.length) {
      elements.attr('placeholder', '00,000.00')
      mFieldFormatter.apply(elements, {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      })
    }
  },
  phone: (elements) => {
    if (elements.length) {
      elements
        .prop('placeholder', '(000) 000 - 0000')
        .prop('maxlength', 16)
        .attr('data-rule-phone_ruler', true)

      mFieldFormatter.apply(elements, {
        numericOnly: true,
        delimiters: ['(', ') ', ' - '],
        blocks: [0, 3, 3, 4],
      })
    }
  },
  ssnId: (elements) => {
    if (elements.length) {
      elements
        .prop('placeholder', '000-00-0000')
        .prop('maxlength', 11)
        .attr('data-rule-ssn_id_ruler', true)

      mFieldFormatter.apply(elements, {
        numericOnly: true,
        delimiters: ['-', '-'],
        blocks: [3, 2, 4],
      })
    }
  },
  taxId: (elements) => {
    if (elements.length) {
      elements
        .prop('placeholder', '00-0000000')
        .prop('maxlength', 10)
        .attr('data-rule-tax_id_ruler', true)

      mFieldFormatter.apply(elements, {
        numericOnly: true,
        delimiters: ['-'],
        blocks: [2, 7],
      })
    }
  },
  zipCode: (elements) => {
    if (elements.length) {
      elements
        .prop('placeholder', '00000')
        .prop('max', 99999)
        .attr('data-rule-zip_code_ruler', true)

      mFieldFormatter.apply(elements, {
        numericOnly: true,
        delimiters: [''],
        blocks: [5],
      })
    }
  },
  monthYear: (elements) => {
    if (elements.length) {
      elements
        .prop('placeholder', 'MM/YYYY')
        .attr('data-rule-month_year_ruler', true)

      mFieldFormatter.apply(elements, {
        date: true,
        delimiter: '/',
        datePattern: ['m', 'Y'],
      })
    }
  },
}

const mHtml = {
  asHtml: (element) => jQuery('<div>').append(element.clone()).html(),
  astrics: () => `<span class="text-danger star">*</span>`,
  aTag: (data, url, _blank = true, options = {}) =>
    url
      ? `<a href="${url}" class="${options?.class || ''}" target="${
          _blank ? '_blank' : ''
        }">${mSafe.value(data)}</a>`
      : mSafe.value(data),
  metaTitle: (title) =>
    (document.title = `${title} | ${jQuery('body').attr('data-site-title')}`),

  link: (data) => {
    if (data?.url) {
      return `<a href="${data?.url}" target="${
        data?.target || '_blank'
      }">${mSafe.value(data?.title)}</a>`
    }
    return mSafe.value(data?.title)
  },

  create: (tag, content, attributes = {}, asHtml = false) => {
    let element = jQuery(tag)

    if (content !== undefined && content !== '' && content !== null) {
      if (asHtml) element.html(content)
      else element.text(content)
    }

    if (attributes && typeof attributes === 'object') {
      element.attr(attributes)
      ;['disabled', 'readonly', 'checked'].forEach(function (key) {
        if (attributes.hasOwnProperty(key)) {
          element.prop(key, !!attributes[key])
        }
      })
    }
    return element
  },

  checkboxCreate: function (label, params = {}) {
    let checkbox = mHtml.create(
      `<input type="checkbox" class="custom-control-input" />`,
      '',
      params?.options ?? {}
    )
    let span = mHtml.create(`<span class="custom-control-label" />`, label, {})
    if (params?.htmlContent) {
      span.append(params?.htmlContent)
    }

    return jQuery(
      `<label class="custom-control custom-checkbox" />`
    ).fHtmlRender([checkbox, span])
  },

  checkbox: (params = {}, label = '') =>
    jQuery(`<label class="custom-control custom-checkbox" />`)
      .append(
        jQuery(
          `<input type="checkbox" class="custom-control-input ${params?.inputClass}" />`
        ).attr(params)
      )
      .append(
        jQuery(
          `<span class="custom-control-label ${params?.labelClass ?? ''}" />`
        ).text(params?.label ?? '')
      ),

  radio: (params = {}, label = '') =>
    jQuery(`<label class="custom-control custom-radio" />`)
      .append(
        jQuery(
          `<input type="radio" class="custom-control-input ${params?.inputClass}" />`
        ).attr(params)
      )
      .append(
        jQuery(
          `<span class="custom-control-label ${params?.labelClass ?? ''}" />`
        ).text(params?.label ?? '')
      ),

  fileDragAndDrop: function (params, cb) {
    let trDroppable = `${params.table} .file-droppable`

    jQuery(document).on('dragenter', trDroppable, function (event) {
      event.stopPropagation()
      event.preventDefault()
      jQuery(trDroppable).removeClass('active')
      jQuery(event.currentTarget).addClass('active')
    })

    jQuery(document).on('dragleave', trDroppable, function (event) {
      event.stopPropagation()
      event.preventDefault()
      jQuery(event.currentTarget).removeClass('active')
    })

    jQuery(document).on('dragover', trDroppable, function (event) {
      event.stopPropagation()
      event.preventDefault()
      jQuery(event.currentTarget).addClass('active')
    })

    jQuery(document).on('drop', `${trDroppable}.active`, function (event) {
      event.stopPropagation()
      event.preventDefault()
      let tr = jQuery(event.currentTarget)
      let files = event.originalEvent.dataTransfer.files
      if (files.length == 0) {
        tr.removeClass('active')
        mToast.show(0, 'Please select at least one file.')
      } else {
        tr.removeClass('active')
        cb(tr, files)
      }
    })
  },
  tableDroppable: (table) => {
    mHtml.fileDragAndDrop(
      {
        table: `${table.tableId}`,
      },
      (tr, files) => {
        // if (files.length > 1) {
        //   tr.removeClass('active')
        //   mToast.show(0, 'Please upload only one file.')
        //   return
        // }

        let formData = new FormData()
        Array.from(files).forEach((file) => formData.append('file[]', file))
        mAjax.call(
          {
            formData: true,
            type: 'POST',
            url: tr.attr('data-url'),
            data: formData,
          },
          (success, response) => success && table?.refresh()
        )
      }
    )
  },
  tableUndroppable: (table) => {
    let trDroppable = `${table.tableId} .file-droppable`
    console.log('trDroppable', trDroppable)
    console.log('table.tableId', jQuery(table.tableId))
    console.log(
      '`${table.tableId} .file-droppable`',
      jQuery(`${table.tableId} .file-droppable`)
    )

    jQuery(document).off('dragenter', trDroppable, function (event) {
      console.log('DD dragenter')
    })

    jQuery(document).off('dragleave', trDroppable, function (event) {
      console.log('DD dragleave')
    })

    jQuery(document).off('dragover', trDroppable, function (event) {
      console.log('DD dragover')
    })

    jQuery(document).off('drop', `${trDroppable}.active`, function (event) {
      console.log('DD drop')
    })
  },

  viewPopup: (url) => {
    mAjax.call(
      {
        type: 'GET',
        url: url,
        data: {},
      },
      (success, response) => {
        if (success) {
          let modal = jQuery('#crud-view-modal')
          let modalBody = modal.find('.modal-body')
          if (response?.values?.length) {
            modalBody.find('.table').remove()
            let table = jQuery(
              `<table class="table table-bordered"><thead><tr><th>Field</th><th>Value</th></tr></thead><tbody></tbody></table>`
            ).appendTo(modalBody)
            response.values.forEach((item) => {
              let row = jQuery('<tr></tr>')
              jQuery('<td>').text(item.title).appendTo(row)
              jQuery('<td>').text(item.value).appendTo(row)
              row.appendTo(table.find('tbody'))
            })
          }

          modal.find('.modal-title').text(response.title)
          modal.modal('show')
          mUrl.queryAdd('view', url)
        }
      }
    )
  },

  badge: (text, type = 'badge-primary') =>
    `<div class="badge ${type}">${text}</div>`,
}

const mLoadVars = (cb, url) =>
  mAjax.get(
    url || location.href,
    { action: 'load-vars' },
    (success, response) => cb(success, response)
  )

const mMasterSearch = () => {
  const appContent = jQuery('.app-content')
  const main = jQuery('.top-master-search')
  const url = main.attr('data-url')
  const input = main.find('.search-input-field')
  const searchBox = main.find('.search-list')
  const searchContainer = searchBox.find('.search-list-li')
  const btnSearch = jQuery('.btn-master-search')
  const btnClose = main.find('.search-input-close')

  let searchType = 'loans'
  let searchText = ''

  const init = function () {
    btnSearch.on('click', function (e) {
      main.removeClass('d-none')
      appContent.addClass('show-overlay')
      searchBox.addClass('show')
      input.trigger('focus').trigger('keyup')
    })

    input.on('keyup', (e) => startSearch((searchText = e.target.value.trim())))

    searchContainer.on('click', '.tab-search', function (e) {
      searchType = jQuery(this).attr('data-type')
      input.trigger('keyup')
    })

    btnClose.on('click', function (e) {
      main.addClass('d-none')
      appContent.removeClass('show-overlay')
      searchBox.removeClass('show')
    })
  }

  const startSearch = () => {
    if (searchText.length >= 2) {
      startLoading(true)
      mAjax.call(
        {
          type: 'GET',
          url: url,
          data: {
            type: searchType,
            search: searchText,
          },
          loader: false,
        },
        (success, response) => {
          success
            ? setResponse('data', response)
            : setResponse('error', response)
          startLoading(false)
        }
      )
    } else {
      startLoading(false)
      setResponse('min-chars')
    }
  }

  const startLoading = (action) =>
    action ? searchBox.addClass('loading') : searchBox.removeClass('loading')

  const setResponse = function (type, response) {
    if (type === 'min-chars') {
      searchContainer.html(
        '<p class="mb-0 text-danger">The search must be at least 2 characters.</p>'
      )
    } else if (type === 'error') {
      searchContainer.html(
        `<p class="mb-0 text-danger">${mAjax.errorMessage(response)}</p>`
      )
    } else {
      let tabLIs = []
      let tabContents = []
      for (let key in response.tabs) {
        tabLIs.push(
          `<li class="nav-item"><a class="nav-link tab-search ${
            key === response.type ? 'active' : ''
          }" data-toggle="tab" href="#search-tab-${key}" data-type="${key}">${mSafe.value(
            response.tabs[key].title
          )} (${mSafe.value(response.tabs[key].counts)})</a></li>`
        )

        let list = (response.data[key] ?? []).map((item) =>
          item?.url
            ? `<li class="list-group-item"><a href="${
                item.url
              }" target="_blank">${mSafe.highlightText(
                item.title,
                searchText
              )}</a></li>`
            : `<li class="list-group-item">${mSafe.highlightText(
                item.title,
                searchText
              )}</li>`
        )
        if (!list.length) {
          list.push('<li class="list-group-item">No records found.</li>')
        }
        tabContents.push(
          `<div class="tab-pane ${
            key === response.type ? 'active' : ''
          }" id="search-tab-${key}"><ul class="list-group list-group-circle text-left">${list.join(
            ''
          )}</ul></div>`
        )
      }

      if (tabLIs.length) {
        searchContainer.html(
          `<div class="nav-vertical"><ul class="nav nav-tabs nav-left flex-column" role="tablist">${tabLIs.join(
            ''
          )}</ul><div class="tab-content">${tabContents.join('')}</div></div>`
        )
      }
    }
  }

  const close = () => btnClose.trigger('click')

  return {
    init,
    close,
  }
}

const mMenuHorizontal = function () {
  const body = jQuery('body')
  const hLeftMenu = jQuery('#horizontal-layout-vertical-menu')
  const topMenuWrapper = jQuery('.header-new .horizontal-menu-wrapper')
  const topMenuElement = jQuery('.header-new .header-navigation')
  let topMenuLIs = []
  let topMenuLIArray = []
  let topMenu3Dots = {
    li: null,
    subUl: null,
  }

  const init = function () {
    let $sideNavOverlay = jQuery('.sidenav-overlay')

    if (topMenuElement.length) {
      topMenuLIs = topMenuElement.find('li.level-1')
      topMenuLIs.each((index, element) => {
        topMenuLIArray.push({
          width: jQuery(element).width(),
          element: jQuery(element),
        })
      })
      topMenuWrapper.addClass('d-none d-md-block d-lg-block d-xl-block')
      topMenuWrapper.removeClass('oct')
      setupTopMenu3Dots()
      setHeaderNavigation()
    }
    // Horizontal layout + vertical menu events
    jQuery(document).on(
      'click',
      '.horizontal-layout .header-new .menu-toggle',
      function (e) {
        body.removeClass('menu-hide').addClass('menu-open')
        $sideNavOverlay.addClass('show')
      }
    )
    jQuery(document).on(
      'click',
      '.sidenav-overlay, .modern-nav-toggle',
      function (e) {
        body.removeClass('menu-open').addClass('menu-hide')
        $sideNavOverlay.removeClass('show')
      }
    )
    // Disable click for top menu
    jQuery(document).on(
      'mouseleave',
      '.horizontal-layout .header-new #top-navigation .nav-item',
      function (e) {
        jQuery(this).removeClass('show')
        jQuery(this).find('.dropdown-toggle').prop('aria-expanded', false)
        jQuery(this).find('.dropdown-menu').removeClass('show')
      }
    )
    verticalLayoutEffects()
    jQuery(window).on('resize', function () {
      verticalLayoutEffects()
      setHeaderNavigation()
    })
  }

  const setupTopMenu3Dots = function () {
    let icon = feather.icons['more-horizontal'].toSvg({})
    topMenu3Dots.li = jQuery(
      `<li class="level-1 dropdown li-with-sub-menu li-3dot"><a class="dropdown-toggle nav-link have-sub-menu nav-link-3-dots" href="javascript:;" data-toggle="dropdown">${icon}</a><ul class="dropdown-menu open-left level-3dots"></ul></li>`
    )
    topMenu3Dots.subUl = topMenu3Dots.li.find('ul.dropdown-menu.level-3dots')
  }

  const setHeaderNavigation = function () {
    if (topMenuWrapper.length) {
      if (jQuery(window).width() < 768) {
        body.addClass(
          'vertical-layout vertical-overlay-menu fixed-navbar menu-hide'
        )
        hLeftMenu.removeClass('d-none')
      } else {
        body.removeClass(
          'vertical-layout vertical-overlay-menu fixed-navbar menu-hide'
        )
        hLeftMenu.addClass('d-none')
      }
      let containerWidth = topMenuWrapper[0].clientWidth - 75 // 70 for 3 dots
      topMenuElement.html('')
      topMenu3Dots.subUl.html('')
      let childrenVisible = []
      let children3Dots = []
      let childrenWidth = (topMenuLIs.length - 1) * 0
      for (let k in topMenuLIArray) {
        let temp = topMenuLIArray[k]
        childrenWidth = childrenWidth + temp.width
        if (childrenWidth > containerWidth) {
          children3Dots.push(temp.element)
        } else {
          childrenVisible.push(temp.element)
        }
      }
      for (let k in childrenVisible) {
        childrenVisible[k].appendTo(topMenuElement)
      }
      if (children3Dots.length) {
        topMenu3Dots.li.appendTo(topMenuElement)
        for (let k in children3Dots) {
          children3Dots[k].removeClass('level-1').addClass('dropdown-submenu')
          children3Dots[k].appendTo(topMenu3Dots.subUl)
        }
        topMenu3Dots.subUl
          .find('.nav-link')
          .removeClass('nav-link')
          .addClass('dropdown-item')
      }
    }
  }
  const verticalLayoutEffects = function () {
    if (body.hasClass('vertical-layout')) {
      if (jQuery(window).width() <= 1199) {
        body
          .removeClass('vertical-menu-modern')
          .addClass('vertical-overlay-menu')
      } else {
        body
          .removeClass('vertical-overlay-menu')
          .addClass('vertical-menu-modern')
      }
    }
  }

  return {
    init,
  }
}

const mPassword = {
  policy: (required = false) => ({
    required: required,
    minlength: 8,
    containsLowercase: true,
    containsUppercase: true,
    containsNumber: true,
    containsSpecialChar: true,
    noLeadingTrailingSpace: true,
  }),
}

const mPrint = {
  amount: (n, d = '-', decimals = 2) => {
    if (d === undefined) d = '-'

    d = isNaN(n)
      ? d
      : new Intl.NumberFormat('en', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
          // trailingZeroDisplay: 'stripIfInteger'
        }).format(n)

    return mSafe.value(d)
  },

  amountWo: (n, d = '-') => {
    if (d === undefined) d = '-'

    if (isNaN(n)) {
      return mSafe.value(d)
    }

    let a = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
      // minimumFractionDigits: 6,
      // maximumFractionDigits: 6,
      trailingZeroDisplay: 'stripIfInteger',
    }).format(n)

    return a.replace('$', '')
  },

  html: {
    greenRed: (content, splitter) => {
      content = mSafe.value(content)
      splitter = mSafe.value(splitter.replace('green-red:', '')).split('-')
      return `<span class="badge badge-pill ${
        content === splitter?.[0]
          ? 'badge-light-success'
          : 'badge-light-warning'
      }">${content}</span>`
    },
  },

  readMore: {
    content: function (content, length = 100) {
      length = isNaN(length)
        ? mSafe.int(length.replace('read-more:', '') ?? 0)
        : length

      content = mSafe.content(content)
      if (content && content.length > length) {
        let s = content.substring(0, length - 4) + ' '
        return `<div class="read-more-container">
            <div class="read-more-content" data-short="${s}" data-large="${content}">${s}</div>
            <strong class="badge badge-light-primary cursor-pointer read-more-handler">...</strong>
        </div>`
      }
      return content
    },

    link: function (data, length = 10, options = {}) {
      if (!data?.url) {
        return mPrint.readMore.content(data?.title, length)
      }

      if (data?.title && data?.title?.length > length) {
        let s = data?.title.substring(0, length - 4) + ' '
        return `<div class="read-more-container">
            <a class="read-more-content ${options?.class || ''}" href="${
          data?.url
        }" data-short="${s}" data-large="${data?.title}">${s}</a>
            <strong class="badge badge-light-primary cursor-pointer read-more-handler">...</strong>
        </div>`
      }

      return mHtml.aTag(data?.title, data?.url, false, options)
    },

    list: (list) => {
      if (!list.length) {
        return '-'
      }

      list = list.map((item) =>
        item.url
          ? `<a target="_blank" href="${item.url}">${mSafe.value(
              item.name
            )}</a>`
          : mSafe.value(item.name)
      )
      if (list.length === 1) {
        return list[0]
      } else {
        return `<div class="read-more-list"><div class="data-content">${
          list[0]
        } <a href="javascript:;" class="enlarge-list badge badge-light-primary">+${
          list.length - 1
        }</a></div><div class="data-content d-none">${list.join(
          '<br />'
        )} <a href="javascript:;" class="enlarge-list badge badge-light-primary">-${
          list.length - 1
        }</a></div></div>`
      }
    },

    data: (data, length = 20) => {
      if (typeof data === 'string') {
        length = isNaN(length)
          ? mSafe.int(length.replace('read-more:', '') ?? 0)
          : length

        data = mSafe.content(data)
        if (data && data.length > length) {
          let s = data.substring(0, length - 4) + ' '
          return `<div class="read-more-container">
                <div class="read-more-content" data-short="${s}" data-large="${data}">${s}</div>
                <strong class="badge badge-light-primary cursor-pointer read-more-handler">...</strong>
            </div>`
        }
        return data
      } else if (typeof data === 'object' && data?.title) {
        if (data?.url) {
          if (data?.title && data?.title?.length > length) {
            let s = data?.title.substring(0, length - 4) + ' '
            return `<div class="read-more-container">
                <a class="read-more-content ${data?.class}" href="${
              data?.url
            }" target="${
              data?.target ? '_blank' : ''
            }" data-short="${s}" data-large="${data?.title}">${s}</a>
                <strong class="badge badge-light-primary cursor-pointer read-more-handler">...</strong>
            </div>`
          }
          return mHtml.aTag(data?.title, data?.url, data?.target ?? false, data)
        }
        return mPrint.readMore.data(data?.title, length)
      }
      return '-'
    },
  },

  percentage: (n, d = '-') => mSafe.value(isNaN(n) ? d : n + '%'),
}

const mTableHelper = {
  cover: (element) => jQuery(element).closest('.cover-data-table'),
  table: (element) => mTableHelper.cover(element).find('.dataTable'),
  tableObj: (element) => mTableHelper.table(element).DataTable(),
  settings: (element) => mTableHelper.tableObj(element).settings(),

  refresh: (element, draw = false) => mTableHelper.tableObj(element).draw(draw),
  rowDroppable: (row, data, index) => {
    if (data.file_missing && data.actions?.drop_file) {
      jQuery(row)
        .addClass('file-droppable')
        .attr('data-url', data.actions?.drop_file)
    }
  },
  rowDropFile: (row, data, index) => {
    if (data.actions?.drop_file) {
      jQuery(row)
        .addClass('file-droppable')
        .attr('data-url', data.actions?.drop_file)
    }
  },

  statusToggle: (splitter, content, url) => {
    content = mSafe.value(content)
    splitter = mSafe.value(splitter.replace('status-toggle:', ''))
    if (url) {
      splitter = splitter.split('-')
      return `<a href="${url}" class="button-dt-status-update badge badge-pill ${
        content === splitter?.[0]
          ? 'badge-light-success'
          : 'badge-light-warning'
      }">${content}</a>`
    }
    return mPrint.html.greenRed(content, splitter)
  },

  documentStatusUpdater: (data, type, row, meta) => {
    let url = row?.actions?.['status-update']

    if (data) {
      if (data === 'Restored') {
        return mSafe.value(data)
      }

      let current = {}
      let statuses = meta?.settings?.json?.documentStatuses ?? []
      if (!url) {
        let status = statuses.find((item) => item.id === data)
        return `<span style="color: ${status.color}">${mSafe.value(
          status.name
        )}</span>`
      }

      let list = []
      statuses.forEach((item) => {
        if (data === 7) {
          if (item.id == data) {
            current = item
          } else if (item.id == 1) {
            // Pending
            list.push(
              `<a class="dropdown-item text-sm button-dt-status-update" href="${url}" data-status="${
                item.id
              }" style="color: ${item.color}">${mSafe.value(item.name)}</a>`
            )
          }
        } else {
          if (item.id == data) {
            current = item
          } else {
            list.push(
              `<a class="dropdown-item text-sm button-dt-status-update" href="${url}" data-status="${
                item.id
              }" style="color: ${item.color}">${mSafe.value(item.name)}</a>`
            )
          }
        }
      })

      return `<div class="btn-group"><button type="button" class="btn btn-sm dropdown-toggle btn-outline-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: ${
        current.color
      }">${mSafe.value(
        current.name
      )}</button><div class="dropdown-menu">${list.join('')}</div></div>`
    } else {
      let list = []
      let current = {}
      let statuses = meta?.settings?.json?.documentStatuses ?? []

      if (!url) {
        let status = statuses.find((item) => item.id === 1) // 1: Pending
        return `<span style="color: ${status.color}">${mSafe.value(
          status.name
        )}</span>`
      }

      statuses.forEach((item) => {
        if (item.id == 1) {
          current = item
          list.push(
            `<a class="dropdown-item button-dt-status-update text-sm" href="${url}" data-status="${
              item.id
            }" style="color: ${item.color}">${mSafe.value(item.name)}</a>`
          )
        } else if (item.id == 7) {
          list.push(
            `<a class="dropdown-item button-dt-status-update text-sm" href="${url}" data-status="${
              item.id
            }" style="color: ${item.color}">${mSafe.value(item.name)}</a>`
          )
        }
      })

      return `<div class="btn-group"><button type="button" class="btn btn-sm dropdown-toggle btn-outline-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: ${
        current.color
      }">${mSafe.value(
        current.name
      )}</button><div class="dropdown-menu">${list.join('')}</div></div>`
    }
  },

  loanInquiryStatusUpdater: (list, value, url) => {
    let data = []
    list.forEach((item, index) => {
      if (index !== value) {
        data.push(
          `<a class="dropdown-item text-${
            item.color
          } text-sm button-dt-status-update" href="${url}" data-status="${index}">${mSafe.value(
            item.title
          )}</a>`
        )
      }
    })

    return `<div class="btn-group"><button type="button" class="btn btn-sm dropdown-toggle btn-outline-${
      list[value].color
    }" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">${mSafe.value(
      list[value].title
    )}</button><div class="dropdown-menu">${data.join('')}</div></div>`
  },

  tdQuickEdit: (type, showData, name, value, row, meta, extra) => {
    if (type === 'date') {
      showData = mDate.date(showData) || '-'
    }
    if (!row?.actions?.inlineEdit) {
      return showData
    }
    return `<div class="editable" data-type="${type}" data-show="${showData}" data-name="${name}" data-value="${value}" data-url="${
      row?.actions?.inlineEdit ?? ''
    }" data-data-key="${extra?.dataKey || ''}">${showData}</div>`
  },

  actionButtons: (data, type, row, meta) => {
    if (!data) {
      return '-'
    }

    let font = `font-medium-2`

    let actions = []
    if (data.edit) {
      actions.push(
        `<a href="${data.edit}" ${mTooltip.apply(
          'Edit',
          'left'
        )}><i data-feather="edit" class="${font}"></i></a>`
      )
    }
    if (data.editAjax) {
      actions.push(
        `<a href="${data.editAjax}" ${mTooltip.apply(
          'Edit',
          'left'
        )} class="dt-ajax-form"><i data-feather="edit" class="${font}"></i></a>`
      )
    }
    if (data.view) {
      actions.push(
        `<a href="${data.view}" ${mTooltip.apply(
          'View',
          'left'
        )}><i data-feather="eye" class="${font}"></i></a>`
      )
    }
    if (data.clone) {
      actions.push(
        `<a href="${data.clone}" ${mTooltip.apply(
          'Clone',
          'left'
        )}><i data-feather="copy" class="${font}"></i></a>`
      )
    }
    if (data.viewAjax) {
      actions.push(
        `<a href="${data.viewAjax}" class="button-dt-view" ${mTooltip.apply(
          'View',
          'left'
        )}><i data-feather="eye" class="${font}"></i></a>`
      )
    }
    if (data.viewBlock) {
      actions.push(
        `<a href="javascript:;" ${mTooltip.apply('History', 'left')} class="${
          data.viewBlock.class
        }" data-view-item="${
          data.viewBlock.item
        }" ><i data-feather="eye" class="${font}"></i></a>`
      )
    }
    if (data?.['loan-notes'] ?? false) {
      actions.push(
        `<a href="${data?.['loan-notes']}" ${mTooltip.apply(
          'Loan Notes',
          'left'
        )} class="button-loan-notes"><i data-feather="file-text" class="${font}"></i></a>`
      )
    }

    if (data.archive) {
      actions.push(
        `<a href="${data.archive}" ${mTooltip.apply(
          'Archive',
          'left'
        )} class="text-danger button-dt-archive"><i data-feather="archive" class="${font}"></i></a>`
      )
    }
    if (data.restore) {
      actions.push(
        `<a href="${data.restore}" ${mTooltip.apply(
          'Restore',
          'left'
        )} class="text-warning button-dt-restore"><i data-feather="rotate-ccw" class="${font}"></i></a>`
      )
    }
    if (data.delete) {
      actions.push(
        `<a href="${data.delete}" ${mTooltip.apply(
          'Delete',
          'left'
        )} class="text-danger button-dt-delete"><i data-feather="trash-2" class="${font}"></i></a>`
      )
    }
    if (data.download) {
      actions.push(
        `<a href="${data.download}" ${mTooltip.apply(
          'Download',
          'left'
        )} class="text-success" target="_blank"><i data-feather="download" class="${font}"></i></a>`
      )
    }
    if (data.preview) {
      actions.push(
        `<a href="${data.preview}" ${mTooltip.apply(
          'Preview',
          'left'
        )} class="" target="_blank"><i data-feather="eye" class="${font}"></i></a>`
      )
    }
    if (data.documents) {
      actions.push(
        `<a href="${data.documents}" ${mTooltip.apply(
          'Documents',
          'left'
        )} class="button-documents-table"><i data-feather="file-text" class="${font}"></i></a>`
      )
    }
    if (data.drop_file) {
      actions.push(
        `<div style="display: flex; align-items: center; justify-content: center; gap: 5px" ${mTooltip.apply(
          'Drag file here',
          'left'
        )} data-url="${
          data.drop_file
        }" class="text-secondary"><i data-feather="upload" class="${font}"></i> Drop file here</div>`
      )
    }
    if (data.history) {
      actions.push(
        `<a href="${data.history}" ${mTooltip.apply(
          'History',
          'left'
        )} class="button-documents-history"><i data-feather="file" class="${font}"></i></a>`
      )
    }
    if (data.history2) {
      actions.push(
        `<a href="${data.history2}" ${mTooltip.apply(
          'History of related document',
          'left'
        )} class="button-documents-history"><i data-feather="file" class="${font}"></i></a>`
      )
    }
    if (data.historyLock) {
      let tContent = [`<span>Archive the document</span>`]
      if (row.restorer) {
        tContent.push(`<br>`)
        tContent.push(
          `<span>Restored on ${mDate.dateTime(
            row.restore_date
          )} by ${mSafe.value(row.restorer)}</span>`
        )
      }
      actions.push(
        `<a href="${
          data.historyLock
        }" class="text-danger button-document-lock-history" ${mTooltip.apply(
          tContent.join(''),
          'left'
        )} ><i data-feather="archive" class="${font}"></i></a>`
      )
    }
    if (data.historyUnlock) {
      let tContent = [`<span>Restore the document</span>`]
      if (row.archiver) {
        tContent.push(`<br>`)
        tContent.push(
          `<span>Archived on ${mDate.dateTime(
            row.archive_date
          )} by ${mSafe.value(row.archiver)}</span>`
        )
      }
      actions.push(
        `<a href="${
          data.historyUnlock
        }" class="text-warning button-document-unlock-history" ${mTooltip.apply(
          tContent.join(''),
          'left'
        )}><i data-feather="rotate-ccw" class="${font}"></i></a>`
      )
    }
    if (data.resendEmail) {
      actions.push(
        `<a href="${
          data.resendEmail
        }" class="button-resend-email-notification" ${mTooltip.apply(
          'Resend Email',
          'left'
        )}><i data-feather="send" class="${font}"></i></a>`
      )
    }

    return actions.length
      ? `<div class="col-actions">${actions.join('')}</div>`
      : `-`
  },
}

export {
  // Default Exports
  mAjax,
  mButton,
  mCaptchaCreator,
  mConfirm,
  mCustomTable,
  mDataTable,
  mDate,
  mDom,
  mFeatherApply,
  mFieldFormatter,
  mForm,
  mHtml,
  mHttp,
  mLoader,
  mLoadVars,
  mMasterSearch,
  mMenuHorizontal,
  mPassword,
  mPrint,
  mSafe,
  mTableHelper,
  mToast,
  mTooltip,
  mUrl,
}
