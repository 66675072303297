import {
  mAjax,
  mDate,
  mFeatherApply,
  mHtml,
  mPrint,
  mSafe,
  mTableHelper,
  mUrl,
  mConfirm,
} from '@helpers'

class mDataTable {
  xhr = null
  coverId = null
  cover = null
  id = null
  table = null
  tableId = null
  tableObj = null
  url = null
  params = {}
  extra = {}
  search = null
  total = 0

  archiveBox = {
    checkbox: null,
    createdIndex: -1,
    deletedIndex: -1,
  }

  isQuickAdd = false
  isQuickEdit = false
  quickActionData = {}

  bulkData = {
    applied: false,
    all: false,
    values: [],
  }

  constructor(id, args = {}) {
    this.id = id
    this.tableId = `#${this.id}`
    this.tableObj = jQuery(this.tableId)
    this.url = this.tableObj.attr('data-url') || args?.url

    // SetUp Cover Card
    if (jQuery(`#card_${this.id}`).length) {
      this.coverId = `#card_${this.id}`
      this.cover = jQuery(`${this.coverId}`)
      if (this.cover.find('[name="searchArchived"]').length) {
        this.archiveBox.checkbox = this.cover.find('[name="searchArchived"]')

        // SetUp Indexes For Columns
        if (this.archiveBox.checkbox) {
          let totalThs = this.tableObj.find('thead tr:first-child th')
          if (totalThs.length) {
            let idx1 = totalThs.index(
              this.tableObj.find('thead tr th[data-data="created_at"]')
            )
            let idx2 = totalThs.index(
              this.tableObj.find('thead tr th[data-data="deleted_at"]')
            )
            if (idx1 > -1 && idx2 > -1) {
              this.archiveBox.createdIndex = idx1
              this.archiveBox.deletedIndex = idx2
            }
          }
        }
      }
    }

    this.extra = args?.extra || {}
    delete args?.extra

    // Setup Column Defs
    args.columnDefs = this.columnSetDefs(args.columnDefs || [])

    this.params = args
  }

  getCover() {
    return this.cover
  }
  getWrapper() {
    return this.getCover().find('.dataTables_wrapper')
  }
  getTableInfoDiv() {
    return this.cover.find('.dt-table-info')
  }
  getCheckId() {
    return this.cover.find('.dt-check-id')
  }

  init() {
    let _self = this
    if (_self.tableObj.hasClass('dataTable')) {
      _self.tableObj.DataTable().destroy()
    }

    // if (args.allowScroll === true) {
    //     args = {
    //         ...args,
    //         scrollX: true,
    //         sScrollX: '100%',
    //         scrollCollapse: true,
    //     };
    //     delete args.allowScroll;
    // }

    let configs = {
      ajax: {
        type: 'GET',
        url: _self.url,
        data: function (d) {
          let extra = {}
          if (_self.cover != null) {
            let fields = _self.cover.find(':input.dt-search').serializeArray()
            for (let i in fields) {
              if (fields[i].name.includes('[]')) {
                if (typeof extra[fields[i].name] === 'undefined') {
                  extra[fields[i].name] = []
                }
                extra[fields[i].name].push(fields[i].value)
              } else {
                extra[fields[i].name] = fields[i].value
              }
            }
          }

          let additionalSearch = {}
          if (typeof _self?.extra?.extraSearchCallback === 'function') {
            additionalSearch = _self?.extra?.extraSearchCallback()
          }

          return { ...d, ...extra, ...additionalSearch }
        },

        // Abort the previous AJAX request
        beforeSend: function (xhr) {
          if (_self.xhr !== null) {
            _self.xhr.abort()
          }
          _self.xhr = xhr
        },
      },
      autoWidth: false,
      bSortCellsTop: true,
      dom: `rt<"row d-flex justify-content-between dt-footer"<"col-sm-12 col-md-6 dt-footer--left"li><"col-sm-12 col-md-6 dt-footer--right"p>>`,
      drawCallback: function (settings) {
        _self.triggerCreatedDeleted() // Manage created/deleted columns based on archived checkbox
        _self.triggerPageJumper(settings) // Manage page jumper

        _self.extra?.cbDrawCallback &&
          typeof _self.extra?.cbDrawCallback === 'function' &&
          _self.extra?.cbDrawCallback(settings)

        mFeatherApply()
        _self.total = settings._iRecordsTotal

        _self.resetBulkActions()
      },
      // fixedColumns: {left: 2},
      initComplete: function (settings, json) {
        mFeatherApply()

        _self.extra?.cbInitComplete &&
          typeof _self.extra?.cbInitComplete === 'function' &&
          _self.extra?.cbInitComplete(settings)

        let viewLink = mUrl.queryGet('view')
        if (viewLink) {
          mHtml.viewPopup(viewLink)
        }

        _self.isQuickAdd = !!_self.tableObj.find('tr.quick-add-row').length
        _self.initQuickAdd()

        _self.isQuickEdit = !!_self.tableObj.find('.td-editable .editable')
          .length
        _self.initQuickEdit()
        if (json?.dataQuickActions) {
          _self.quickActionData = json.dataQuickActions
        }
      },
      language: {
        lengthMenu: `Show _MENU_ Entries`,
        paginate: { previous: '&nbsp;', next: '&nbsp;' },
        processing: '<div>Processing ...</div>',
      },
      lengthChange: true,
      lengthMenu: _self.getLengthMenu(),
      paging: true,
      displayStart: 0, // Start from page 1
      pageLength: 10,
      processing: true,
      searching: false,
      serverSide: true,
      stateSave: !true,
      responsive: false,
      scrollX: true,
      sScrollX: '100%',
      scrollCollapse: true,
      scrollY: '68.5vh',
      ...this.params,
    }

    if (this.params.paging === false) {
      configs.info = false
    }

    if (configs?.responsive === true) {
      delete configs?.scrollX
      delete configs?.sScrollX
      delete configs?.scrollCollapse
      delete configs?.scrollY
      configs.scrollCollapse = configs.responsive
    }

    if (configs?.scrollX === true) {
      this.tableObj.addClass('display nowrap')
    } else {
      this.tableObj.removeClass('display nowrap')
    }

    configs = _self.applyUserWiseSettings(configs)

    $.fn.dataTable.ext.errMode = 'none' // 'throw'

    this.table = this.tableObj.DataTable(configs)

    this.table.on('processing.dt', (e, settings, processing) =>
      processing
        ? this.processing().addClass('show')
        : this.processing().removeClass('show')
    )
    this.table.on('error.dt', (e, settings, techNote, message) =>
      _self.tableObj
        .find('tbody')
        .html(
          `<tr><td colspan="${
            _self.tableObj.find('thead th').length
          }"><p class="text-center text-danger">${mSafe.value(
            settings?.jqXHR?.responseJSON?.message ?? message
          )}</p></td></tr>`
        )
    )
    this.table.on('order.dt', (e, settings) =>
      _self.syncQueryParams(e, settings)
    )
    this.table.on('page.dt', (e, settings) =>
      _self.syncQueryParams(e, settings)
    )
    this.table.on('length.dt', (e, settings, len) =>
      _self.syncQueryParams(e, settings)
    )

    this.applyEvents()

    return this
  }

  reload() {
    this.table.clear().draw()
  }

  refresh(draw = false) {
    this.tableObj.DataTable().draw(draw)
  }

  destroy() {
    this.initQuickAdd(false)
    this.initQuickEdit(false)
    this.applyEvents(false)
    this.allowBulkActions(false)
    this.table.destroy()
  }

  processing() {
    return jQuery(`${this.tableId}_processing`)
  }
  jsonData() {
    return this.table.settings()[0].json
  }

  syncQueryParams(e, settings) {
    if (this.extra?.urlSync === false) {
      return
    }

    let searchParams = new URLSearchParams(window.location.search)
    let currentQuery = {}
    for (const key of searchParams.keys()) {
      currentQuery[key] = searchParams.get(key)
    }

    let newParams = {
      ...currentQuery,
      start: this.table.page.info().start,
      length: this.table.page.len(),
      order: this.table.order().join(','),
      ...(this?.table?.settings() ? this.table.settings()[0].ajax.data() : {}),
    }

    if (
      !(this.archiveBox.checkbox && this.archiveBox.checkbox.is(':checked'))
    ) {
      delete newParams?.searchArchived
    }

    mUrl.setQuery(jQuery.param(newParams))
  }
  applyUserWiseSettings(configs) {
    if (this.extra?.ignoreUserSettings) {
      return configs
    }

    let _self = this

    let obj = {}
    try {
      obj = JSON.parse(
        this.tableObj.attr('data-dt-user-settings')
          ? this.tableObj.attr('data-dt-user-settings')
          : '{}'
      )
      obj = Array.isArray(obj) ? {} : obj
    } catch (error) {
      obj = {}
    }

    if (obj?.order?.length) {
      configs.order = [[obj?.order[0]?.column, obj?.order[0]?.dir]]
    }
    if (obj?.start !== undefined && !isNaN(obj?.start)) {
      configs.displayStart = parseInt(obj?.start)
    }

    if (obj?.length !== undefined && !isNaN(obj?.length)) {
      configs.pageLength = parseInt(obj?.length)
    }
    if (obj?.pageLength !== undefined && !isNaN(obj?.pageLength)) {
      configs.pageLength = parseInt(obj?.pageLength)
    }

    // From the URL
    let searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('start') && searchParams.get('start') !== undefined) {
      configs.displayStart = parseInt(searchParams.get('start'))
    }
    if (
      searchParams.has('length') &&
      searchParams.get('length') !== undefined
    ) {
      if (_self.isLengthMenuValid(searchParams.get('length').toString())) {
        configs.pageLength = parseInt(searchParams.get('length'))
      }
    }
    if (searchParams.has('order') && searchParams.get('order') !== undefined) {
      let order = searchParams.get('order').split(',')
      if (
        order.length === 2 &&
        !isNaN(order[0]) &&
        ['asc', 'desc'].includes(order[1])
      ) {
        configs.order = [[parseInt(order[0]), order[1]]]
      }
    }

    return configs
  }

  getLengthMenu() {
    return jQuery('body').attr('data-dt-lenth-menu').split(',')
  }

  isLengthMenuValid(value) {
    return this.getLengthMenu()?.includes(value)
  }

  columnSetDefs(cols) {
    let _self = this

    // Set from the attributes
    let thsObj = _self.tableObj.find(`thead th`)
    if (thsObj.length) {
      let keys = cols.map((item) => item.targets)

      thsObj.each((index, item) => {
        let key = jQuery(item).attr('data-data')
        if (!keys.includes(key)) {
          let dataPrint = jQuery(item).attr('data-print')

          let render = (data, type, row, meta) => mSafe.value(data) || '-'

          switch (key) {
            case 'checkId':
              render = (data, type, row, meta) =>
                [
                  `<label class="custom-control custom-checkbox">`,
                  `<input type="checkbox" class="custom-control-input dt-row-check" value="${data}" />`,
                  `<span class="custom-control-label">&nbsp</span>`,
                  `</label>`,
                ].join('')
              break

            case 'actions':
              render = mTableHelper.actionButtons
              break

            case 'created_at':
            case 'updated_at':
            case 'deleted_at':
              render = (data, type, row, meta) =>
                data ? mSafe.value(mDate.date(data)) : `-`
              break

            default:
              switch (dataPrint) {
                case '%':
                  render = (data, type, row, meta) => mPrint.percentage(data)
                  break

                case 'code':
                  render = (data, type, row, meta) =>
                    data ? `<code>${mSafe.value(data)}</code>` : `-`
                  break

                case 'code':
                  render = (data, type, row, meta) =>
                    data ? `<code>${mSafe.value(data)}</code>` : `-`
                  break

                case 'html':
                  render = (data, type, row, meta) => mSafe.content(data ?? '-')
                  break

                case 'content':
                  render = (data, type, row, meta) => data || '-'
                  break

                case 'date':
                  render = (data, type, row, meta) =>
                    data ? mDate.date(data) : '-'
                  break

                case 'date-time':
                  render = (data, type, row, meta) =>
                    data ? mDate.dateTime(data) : '-'
                  break

                case 'link-edit':
                  render = (data, type, row, meta) =>
                    mHtml.aTag(data, row?.actions?.edit, false)
                  break

                case 'link-view':
                  render = (data, type, row, meta) =>
                    mHtml.aTag(data, row?.actions?.view)
                  break

                case 'col-link-show':
                  render = (data, type, row, meta) => {
                    return mPrint.readMore.link(data, 30) || '-'
                  }
                  break

                case 'col-doc-category-name':
                  render = (data, type, row, meta) =>
                    mPrint.readMore.data(data, 30) || '-'
                  break

                case 'price':
                  render = (data, type, row, meta) => mPrint.amount(data)
                  break

                default:
                  let splitter = dataPrint.split(':')?.[0] || null
                  switch (splitter) {
                    case 'read-more':
                      render = (data, type, row, meta) =>
                        mPrint.readMore.content(data, dataPrint) || '-'
                      break

                    case 'read-more-list':
                      render = (data, type, row, meta) =>
                        mPrint.readMore.list(data, dataPrint)
                      break

                    case 'green-red':
                      render = (data, type, row, meta) =>
                        mPrint.html.greenRed(data, dataPrint)
                      break

                    case 'status-toggle':
                      render = (data, type, row, meta) =>
                        mTableHelper.statusToggle(
                          dataPrint,
                          data,
                          row?.actions?.status
                        )
                      break

                    default:
                      render = (data, type, row, meta) =>
                        mSafe.value(data) || '-'
                      break
                  }

                  break
              }
              break
          }

          if (render) {
            cols.push({
              targets: key,
              render,
            })
          }
        }
      })
    }

    return cols
  }

  applyEvents(apply = true) {
    if (this.coverId === null) {
      return
    }

    let _self = this
    if (apply) {
      // Table Search
      jQuery(document).on(
        'change',
        `${this.coverId} select.dt-search`,
        function (e) {
          e.preventDefault()
          setTimeout(() => {
            _self.refresh()
          }, 100)
        }
      )
      jQuery(document).on(
        'change',
        `${this.coverId} input[type="checkbox"].dt-search`,
        function (e) {
          e.preventDefault()
          setTimeout(() => {
            _self.refresh()
          }, 100)
        }
      )
      jQuery(document).on(
        'keyup',
        `${this.coverId} input[type="text"].dt-search`,
        function (e) {
          e.preventDefault()
          setTimeout(() => {
            _self.refresh()
          }, 100)
        }
      )

      // Export Table
      jQuery(document).on(
        'click',
        `${this.coverId} .button-dt-export`,
        function (e) {
          e.preventDefault()

          let btn = jQuery(this)
          let params = ''
          if (_self.cover != null) {
            params = '&' + _self.cover.find(':input.dt-search').serialize()
          }

          let url = btn.attr('href')
          location.href = url.includes('?')
            ? `${url}&action=export${params}`
            : `${url}?action=export${params}`
        }
      )
    } else {
      jQuery(document).off('change', `${this.coverId} select.dt-search`)
      jQuery(document).off(
        'change',
        `${this.coverId} input[type="checkbox"].dt-search`
      )
      jQuery(document).off(
        'keyup',
        `${this.coverId} input[type="text"].dt-search`
      )
      jQuery(document).off('click', `${this.coverId}.button-dt-export`)
    }
  }

  triggerCreatedDeleted() {
    if (
      this.archiveBox.checkbox &&
      this.archiveBox.createdIndex > -1 &&
      this.archiveBox.deletedIndex > -1
    ) {
      if (this.archiveBox.checkbox && this.archiveBox.checkbox.is(':checked')) {
        this.table.column(this.archiveBox.createdIndex).visible(false)
        this.table.column(this.archiveBox.deletedIndex).visible(true)
      } else {
        this.table.column(this.archiveBox.createdIndex).visible(true)
        this.table.column(this.archiveBox.deletedIndex).visible(false)
      }
    }
  }
  triggerPageJumper(settings) {
    if (!this.cover.find('.dataTables_paginate').length) return
    if (!settings.fnRecordsDisplay()) return
    if (settings.fnRecordsDisplay() <= settings._iDisplayLength) return

    let _self = this
    let jmpPages = Math.ceil(
      settings.fnRecordsDisplay() / settings._iDisplayLength
    )
    let paginate = _self.cover
      .find('.dataTables_paginate')
      .addClass('with-jumper')
    let input = paginate.find('.page-jumper').find('input')
    if (!input.length) {
      input = jQuery(
        `<input type="number" value="" class="form-control form-control-sm" min="1" />`
      )

      jQuery(`<div class="page-jumper" />`)
        .append('Go to', input, `  <span>/ ${jmpPages} pages</span>`)
        .prependTo(paginate)
      input
        .on('keypress', function (e) {
          if (e.which === 13 && e.target.value) {
            let iNewStart = settings._iDisplayLength * (this.value - 1)
            if (iNewStart > settings.fnRecordsDisplay()) {
              settings._iDisplayStart =
                (Math.ceil(
                  (settings.fnRecordsDisplay() - 1) / settings._iDisplayLength
                ) -
                  1) *
                settings._iDisplayLength
              _self.refresh()
              return
            }
            settings._iDisplayStart = iNewStart
            _self.refresh()
          }
        })
        .on('blur', function (e) {
          if (e.target.value) {
            let iNewStart = settings._iDisplayLength * (this.value - 1)
            if (iNewStart > settings.fnRecordsDisplay()) {
              settings._iDisplayStart =
                (Math.ceil(
                  (settings.fnRecordsDisplay() - 1) / settings._iDisplayLength
                ) -
                  1) *
                settings._iDisplayLength
              _self.refresh()
              return
            }
            settings._iDisplayStart = iNewStart
            _self.refresh()
          }
        })
    }
    input.prop('max', jmpPages)
  }

  // Quick Add ----------------------------------------------------------------
  initQuickAdd(apply = true) {
    if (!this.isQuickAdd) {
      return
    }

    let _self = this
    if (apply) {
      let trBtn = _self.cover.find('.quick-add-button')
      let trData = _self.cover
        .find('.dataTables_scrollHead')
        .find('.quick-add-data')
      let url = trData.attr('data-url')

      let toggleRaws = () => {
        trBtn.toggleClass('d-none')
        trData.toggleClass('d-none')
      }
      jQuery(document).on(
        'click',
        `${this.coverId} .button-dt-quick-add-start`,
        function (e) {
          e.preventDefault()
          toggleRaws()
        }
      )

      jQuery(document).on(
        'click',
        `${this.coverId} .button-dt-quick-add-submit`,
        function (e) {
          mAjax.post(
            url,
            trData.find(':input').serialize(),
            (success, response) => {
              if (success) {
                toggleRaws()
                _self.refresh()
              }
            }
          )
        }
      )
      jQuery(document).on(
        'click',
        `${this.coverId} .button-dt-quick-add-cancel`,
        function (e) {
          toggleRaws()
        }
      )
    } else {
      jQuery(document).off(
        'click',
        `${this.coverId} .button-dt-quick-add-start`
      )
      jQuery(document).off(
        'click',
        `${this.coverId} .button-dt-quick-add-submit`
      )
      jQuery(document).off(
        'click',
        `${this.coverId} .button-dt-quick-add-cancel`
      )
    }
  }
  static closeQuickAdds() {
    jQuery('.cover-data-table').each(function () {
      jQuery(this).find('tr.quick-add-data').addClass('d-none')
      jQuery(this).find('tr.quick-add-button').removeClass('d-none')
    })
  }

  // Quick Edit ----------------------------------------------------------------
  static elementsQuickEdit = []
  quickEdit = {
    url: null,
    element: null,
  }
  initQuickEdit(apply = true) {
    if (!this.isQuickEdit) {
      return
    }

    let _self = this

    if (apply) {
      jQuery(document).on(
        'dblclick',
        `${_self.coverId} td.td-editable`,
        function (e) {
          _self.triggerQuickEditClear()

          let td = jQuery(this)
          let editable = td.find('.editable')
          if (editable.hasClass('active')) {
            return
          }

          let type = editable.attr('data-type')
          let name = editable.attr('data-name')
          let value = editable.attr('data-value')

          _self.quickEdit.url = editable.attr('data-url')

          editable.addClass('active')
          if (type === 'number' || type === 'text') {
            _self.quickEdit.element = jQuery(
              `<input type="${type}" class="form-control" data-name="${name}" value="${value}" data-old-value="${value}" />`
            )
            _self.quickEdit.element.on('blur', function (e) {
              _self.triggerQuickEditSubmit()
            })
            _self.quickEdit.element.on('keydown', function (e) {
              if (e.which == 13) {
                _self.triggerQuickEditSubmit()
              }
            })
            editable.html(_self.quickEdit.element)
            _self.quickEdit.element.focus()
          } else if (type === 'date') {
            _self.quickEdit.element = jQuery(
              `<input type="text" class="form-control cls-datepicker" data-name="${name}" value="${mDate.date(
                value,
                ''
              )}" data-old-value="${value}" data-static="false" />`
            )
            _self.quickEdit.element.on('blur', function (e) {
              _self.triggerQuickEditClear()
            })
            _self.quickEdit.element.on('keydown', function (e) {
              if (e.which == 13) {
                _self.triggerQuickEditClear()
              }
            })
            editable.html(_self.quickEdit.element)
            editable.fFullSetup()

            // TODO: Can be destroy in other ways - keeping element with esc and dbl clicks again
            _self.quickEdit.element.fDateOnChange((value, value2) =>
              _self.triggerQuickEditSubmit()
            )

            _self.quickEdit.element.focus()
          } else if (type === 'multi-select' || type === 'select') {
            let dataValues =
              _self.quickActionData?.[editable.attr('data-data-key')] || []

            _self.quickEdit.element = jQuery(
              `<select class="form-control select2" data-name="${name}" data-old-value="${value}" data-target-to="body" data-target-class="quick-action-select-dropdown" data-allow-clear="false" ${
                type === 'multi-select' ? 'multiple' : ''
              } />`
            )
            _self.quickEdit.element.on('change', (e) =>
              _self.triggerQuickEditSubmit()
            )

            editable.html(_self.quickEdit.element)
            _self.quickEdit.element
              .fSelectUpdate(dataValues)
              .fSelectValue(value)
          }
        }
      )
    } else {
      _self.triggerQuickEditClear()
      jQuery(document).off('dblclick', `${_self.coverId} td.td-editable`)
    }
  }
  triggerQuickEditSubmit() {
    if (!this.isQuickEdit) {
      return
    }

    let _self = this
    if (!_self.quickEdit.element) {
      _self.triggerQuickEditClear()
      return
    }

    if (
      _self.quickEdit.element[0].defaultValue == _self.quickEdit.element.val()
    ) {
      _self.triggerQuickEditClear()
      return
    }

    mAjax.post(
      _self.quickEdit.url,
      {
        key: _self.quickEdit.element.attr('data-name'),
        value: _self.quickEdit.element.val(),
      },
      (success, response) => {
        _self.triggerQuickEditClear()
        if (success) {
          _self.refresh()
        }
      }
    )
  }
  triggerQuickEditClear() {
    if (!this.isQuickEdit) {
      return
    }

    let _self = this

    if (_self.quickEdit.element) {
      if (_self.quickEdit.element.hasClass('flatpickr-input')) {
        _self.quickEdit.element.fDatePickerDestroy()
      } else if (
        _self.quickEdit.element.hasClass('select2-hidden-accessible')
      ) {
        _self.quickEdit.element.select2('destroy')
      }
    }

    _self.quickEdit.url = null
    _self.quickEdit.element = null

    mDataTable.closeQuickEdits(this.cover)
  }
  static closeQuickEdits(cover = null) {
    cover = cover ? cover : jQuery('.cover-data-table')
    cover.find('td.td-editable .editable.active').each(function () {
      jQuery(this).html(jQuery(this).attr('data-show')).removeClass('active')
    })
  }

  getTableFilters() {
    let _self = this
    let fields = _self.cover.find(':input.dt-search').serializeArray()
    let extra = {}
    for (let i in fields) {
      if (fields[i].name.includes('[]')) {
        if (typeof extra[fields[i].name] === 'undefined') {
          extra[fields[i].name] = []
        }
        extra[fields[i].name].push(fields[i].value)
      } else {
        extra[fields[i].name] = fields[i].value
      }
    }
    return extra
  }

  // Bulk Actions ----------------------------------------------------------------
  resetBulkActions() {
    this.bulkData.all = false
    this.getCheckId().val('1')
    this.getCheckId().prop('checked', false)
    this.showAllSelectedMessage()
  }

  isMainCheckboxChecked() {
    return this.getCheckId().is(':checked')
  }
  showAllSelectedMessage() {
    let _self = this
    if (_self.isMainCheckboxChecked() && _self.bulkData.all) {
      _self
        .getTableInfoDiv()
        .append(
          `<div class="dt-table-info-data all-seleceted"><strong>All ${_self.total} records on this table are selected.</strong></div>`
        )
    } else {
      _self.getTableInfoDiv().find('.all-seleceted').remove()
    }
  }
  allowBulkActions(apply = true) {
    let _self = this

    if (apply) {
      jQuery(document).on(
        'change',
        `${this.coverId} .dt-check-id`,
        function (e) {
          e.preventDefault()

          jQuery(`${_self.coverId} .dt-row-check`).prop(
            'checked',
            e.target.checked
          )
          _self.getCheckId().val('1')
          _self.bulkData.all = false

          if (e.target.checked) {
            mConfirm(
              function (allow) {
                if (allow) {
                  _self.bulkData.all = true
                  _self.getCheckId().val('2')
                }

                _self.showAllSelectedMessage()
              },
              {
                title: 'Confirm',
                text: `Do you want to select all the records?`,
                confirmButtonText: 'Select All',
                cancelButtonText: 'Only Current Page',
              }
            )
          } else {
            _self.showAllSelectedMessage()
          }
        }
      )

      jQuery(document).on(
        'change',
        `${this.coverId} .dt-row-check`,
        function (e) {
          e.preventDefault()

          _self.getCheckId().prop('checked', false)

          _self.showAllSelectedMessage()
        }
      )
    } else {
      jQuery(document).off('change', `${this.coverId} .dt-check-id`)
    }
  }
  getCheckedIds() {
    if (this.bulkData.all) return ['all']

    let ids = []
    jQuery(`${this.coverId} .dt-row-check:checked`).each(function () {
      ids.push(jQuery(this).val())
    })
    return ids
  }
}

export default mDataTable
