import { mSafe } from '@helpers'

export function isDateValid(value) {
  if (value.includes('/')) {
    value = value.split('/')
    if (value.length === 3) {
      return isDateValid([value[2], value[0], value[1]].join('-'))
    }
    return false
  } else {
    return !isNaN(new Date(value))
  }
}

const single = {
  format: (type, placeholder = 0) => {
    if (type == 'date') return placeholder ? 'MM/DD/YYYY' : 'm/d/Y'
    else if (type == 'time') return placeholder ? 'HH:II' : 'H:i'
    else if (type == 'datetime')
      return placeholder ? 'MM/DD/YYYY HH:II' : 'm/d/Y H:i'
  },
  apply: (element, type, params = {}) => {
    element = typeof element == 'string' ? jQuery(element) : element

    if (!element.length) return

    if (type === 'date') {
      params = {
        ...params,
        enableTime: false,
        noCalendar: false,
        dateFormat: single.format(type),
      }
    } else if (type === 'datetime') {
      params = {
        ...params,
        enableTime: true,
        time_24hr: true,
        minuteIncrement: 1,
        // static: true,
      }
    } else if (type === 'time') {
      params = {
        ...params,
        enableTime: true,
        time_24hr: true,
        noCalendar: true,
      }
    }

    element.attr('placeholder', single.format(type, 1))
    element.prop('autocomplete', 'off')

    const fp = element.flatpickr({
      // allowInput: true,
      allowInput: true,
      allowInvalidPreload: true,
      static: true,
      dateFormat: single.format(type),
      position: element.attr('data-position') || 'auto',
      onReady: function (selectedDates, dateStr, instance) {
        if (instance.isMobile) {
          jQuery(instance.mobileInput).attr('step', null)
        }
      },
      ...params,
    })

    element[0].addEventListener('paste', (event) => {
      event.preventDefault()
      const dt = event.clipboardData.getData('text')
      if (isDateValid(dt)) {
        fp.setDate(dt, true)
      } else {
        fp.setDate(new Date(), true)
      }
    })
    element[0].addEventListener('keyup', (event) => {
      event.preventDefault()
      let dt = event.target.value || ''
      if (dt.length >= 10) {
        if (isDateValid(dt)) {
          fp.setDate(dt, true)
        } else {
          fp.setDate(new Date(), true)
        }
      }
    })

    return fp
  },
  convert: (value) =>
    value ? (value.includes('/') ? new Date(value) : value) : '',
  date: (value, defaultValue = null) => {
    if (value instanceof Date) return moment(value).format('MM/DD/YYYY')
    else if (value?.includes('-')) return moment(value).format('MM/DD/YYYY')
    else if (value?.includes('/'))
      return moment(new Date(value)).format('MM/DD/YYYY')
    return mSafe.content(defaultValue)
  },
  dateTime: (value, defaultValue = null) => {
    if (value instanceof Date) return moment(value).format('MM/DD/YYYY hh:mm A')
    else if (value?.includes('-'))
      return moment(value).format('MM/DD/YYYY hh:mm A')
    else if (value?.includes('/'))
      return moment(new Date(value)).format('MM/DD/YYYY hh:mm A')
    return mSafe.content(defaultValue)
  },
  monthYear: (value) =>
    value
      ? value.length > 7
        ? moment(single.convert(value)).format('MM/YYYY')
        : value
      : '',
  db: (value) => {
    if (value?.includes('-')) return moment(value).format('YYYY-MM-DD')
    else if (value?.includes('/'))
      return moment(new Date(value)).format('YYYY-MM-DD')
    return ''
  },
}
export default single
